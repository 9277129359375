@tailwind base;
@tailwind components;
@tailwind utilities;

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spin buttons for Firefox */
.no-spin {
  -moz-appearance: textfield;
}
